import React from 'react';

import { QueryProvider } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';

import { PepMobile } from './PepMobile';

export const PepMobileContainer = (props) => {
  const { isCustomerIdentified = false } = useThdCustomer() || {};

  const store = useStore({ varnish: false });
  const { storeId, storeZip, isLocalized } = store || {};
  const defaultVariables = {
    storeId,
    zipCode: storeZip,
    skipInstallServices: false,
    skipSpecificationGroup: false,
    isBrandPricingPolicyCompliant: isLocalized ? isCustomerIdentified : false
  };
  return (
    <QueryProvider
      cacheKey="pep-mobile-page"
      defaultVariables={defaultVariables}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PepMobile {...props} />
    </QueryProvider>
  );
};

PepMobileContainer.displayName = 'PepMobileContainer';